<template>
  <div>
    <v-row no-gutters>
      <v-btn @click="toggleLeftPanel" icon >
        <v-icon color="orange">fas fa-bars</v-icon>
      </v-btn>

      <div>
        <v-btn
          v-if="state === 0"
          rounded
          @click="state = 1">
          <v-icon color="primary">far fa-user</v-icon>
        </v-btn>
      </div>


      <v-btn
        v-if="state === 1"
        rounded
        @click="state = 0"
      >
        <v-icon>far fa-times-circle</v-icon>
      </v-btn>

      <!-- Login -->
      <v-btn
        v-if="state === 1 && !$session.exists()"
        :to="{name: 'Login'}"
        @click="state = 0">
        {{$t('common.topbar.login')}}
      </v-btn>

      <!-- Registration -->
      <v-btn
        v-if="state === 1 && !$session.exists()"
        :to="{name: 'Registration'}"
        @click="state = 0">
        {{$t('common.topbar.registration')}}
      </v-btn>

      <!-- My account -->
      <v-btn
        v-if="state === 1 && $session.exists()"
        :to="{name: 'Profile'}">
        {{$t('common.topbar.profile')}}
      </v-btn>

      <!-- Logout -->
      <v-btn
        v-if="state === 1 && $session.exists()"
        @click="logout">
        {{$t('common.topbar.logout')}}
      </v-btn>
    </v-row>

  </div>
</template>

<script>
export default
{
  name: "Account",

  data() {
    return {
      state: 0,
      showLeftPanel: true
    }
  },

  methods: {
    toggleLeftPanel() {

      this.$store.commit("setShowLeftPanel", !this.$store.getters["showLeftPanel"]);
    },

    logout() {
      this.$store.dispatch("logout");
      this.state = 0;
      if (this.$route.name !== "Home")
        this.$router.push({name: "Home"});
    }
  }
}
</script>

<style scoped>

</style>